import {useState} from "react";
import {Button} from "@material-ui/core";
import urls from "../../../routes/api/apiRoutes";
import XLSX from "sheetjs-style";
import axios from "axios";

const ExportExcel = ({doctor}) => {
  const [data, setData] = useState();
  // useEffect(() => {

  // }, [doctor]);

  const exportToExcel = async () => {
    if (doctor == undefined) return;

    const config = {
      params: {
        doctorId: doctor?._id,
      },
    };

    let results;

    axios
      .get(urls.getFinalReportByDoctor, config)
      .then((response) => {
        console.log(config)
        console.log(response);
        setData(response.data);

        let Heading = [
          [
            "Nome",
            "Sexo",
            "Idade",
            "Setor Empresa",
            "IMC",
            "Peso",
            "Altura",
            "Cintura",
            "Cintura/Estatura",
            "Freq. Cardíaca Repouso",
            "Doenças Pré-existentes",
            "Uso de medicação",
            "BetaBloqueador",
            "Tabagismo",
            "Etilismo",
            "Autoavaliação Saúde",
            "Autoavaliação Disp. Física",
            "Autoavaliação Exercício",
            "Autoavaliação Alimentação",
            "Autoavaliação Sono",
            "Autoavaliação Saúde Mental",
            "Risco Idade",
            "Risco Doencas Cronicas",
            "Risco PesoCorporal",
            "Risco Condicionamento Cardiorrespiratorio",
            "Risco Tabagismo",
            "Risco Consumo Alcool",
            "Risco Nutricao",
            "Risco Sono",
            "Risco Saude Mental",
            "Fatores de Risco",
            "Prioridades de Mudança",
            "Disposição p/ Mudança Peso",
            "Disposição p/ Mudança Alimentação",
            "Disposição p/ Mudança Exercício",
            "Disposição p/ Mudança Sono",
            "Disposição p/ Mudança Saúde Mental",
            "Disposição p/ Mudança Tabagismo",
            "Disposição p/ Mudança Etilismo",
            "Alimentação | Número Refeições",
            "Alimentação | Frutas/Vegetais",
            "Alimentação | Laticinios",
            "Alimentação | Cabroidratos",
            "Alimentação | Carnes e ovos",
            "Alimentação | Doces",
            "Alimentação | Fast food ou fritura",
            "Alimentação | Embutidos",
            "Alimentação | Óleos",
            "Alimentação | Agua",
            "Alimentação | Agua Interpretação",
            "Alimentação | Autoavaliação",
            "Alimentação | Avaliação Equalime",
            "Alimentação | Padrões Alimentares",
            "Exercício | Intensidade Baixa",
            "Exercício | Intensidade Moderada",
            "Exercício | Intensidade Alta",
            "Exercício | Motivação",
            "Exercício | Barreiras",
            "Exercício | Vo2max",
            "Exercício | Classific. Vo2max",
            "Exercício | Nivel Ativ. Fisica",
            "Exercício | Cond. Cardio",
            "Exercício | Compativel/Incomp.",
            "Exercício | Aval. Equalime",
            "Sono | Tempo",
            "Sono | Frequência que acorda",
            "Sono | Acorda Cansado?",
            "Sono | Toma Algo?",
            "Sono | Avaliação Equalime",
            "Saúde Mental | Nervoso, ansioso ou no limite?",
            "Saúde Mental | Preocupação",
            "Saúde Mental | Sem Prazer nas coisas",
            "Saúde Mental | Deprimido",
            "Saúde Mental | Vida com propósito",
            "Saúde Mental | Encontrou amigos ou família",
            "Saúde Mental | Indício Ansiedade",
            "Saúde Mental | Indício Depressão",
            "Saúde Mental | Indício Estresse",
            "Saúde Mental | Indício Propósito Vida",
            "Saúde Mental | Conexão",
            "Nota Equalime",
          ],
        ];

        let arrJson = [];
        data.map((fr) => arrJson.push(frToJson(fr)));

        //Had to create a new workbook and then add the header
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet([]);

        XLSX.utils.sheet_add_aoa(ws, Heading);

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, arrJson, {
          origin: "A2",
          skipHeader: true,
        });

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, "filename.xlsx");
      })
      .catch((error) => {
        console.log(error);
        console.log(results);
      });
  };

  // eslint-disable-next-line no-unused-vars
  function frToJson(fr) {
    console.log(fr);

    const frJson = {
      nome: fr.pages[3].values[0],
      sexo: fr.pages[3].values[1],
      idade: fr.pages[3].values[2],
      setorEmpresa: fr.pages[3].values[15],
      imc: fr.pages[3].values[5],
      peso: fr.pages[3].values[6],
      altura: fr.pages[3].values[7],
      cintura: fr.pages[3].values[16],
      cinturaEstatura: fr.pages[3].values[17],
      freqCardiaca: fr.pages[3].values[8],
      doencas: fr.pages[3].values[11],
      usoMed: fr.pages[3].values[12],
      betabloqueador:
        fr.pages[10].values[10] + " – " + fr.pages[10].values[11] + "mg",
      tabagismo: fr.pages[3].values[13],
      etilismo: fr.pages[3].values[14],

      autoSaude: fr.pages[3].values[9],
      autoDispFisica: fr.pages[3].values[10],
      autoExercicio: fr.pages[10].values[33],
      autoAlimentacao: fr.pages[10].values[20],
      autoSono: fr.pages[10].values[55],
      autoSaudeMental: fr.pages[10].values[56],

      riscoIdade: fr.pages[0].values[1],
      riscoDoencas: fr.pages[0].values[2],
      riscoPeso: fr.pages[0].values[3],
      riscoCondCard: fr.pages[0].values[4],
      riscoTabagismo: fr.pages[0].values[5],
      riscoEtilismo: fr.pages[0].values[6],
      riscoAlimentacao: fr.pages[0].values[7],
      riscoSono: fr.pages[0].values[8],
      riscoSaudeMental: fr.pages[0].values[9],
      fatoresRisco: fr.pages[1].values[0],

      
      prioridadesMudanca: fr.pages[8].values[9],
      mudancaPeso: fr.pages[8].values[0],
      mudancaAlimentacao: fr.pages[8].values[1],
      mudancaExercicio: fr.pages[8].values[3],
      mudancaSono: fr.pages[8].values[5],
      mudancaSaudeMental: fr.pages[8].values[7],
      mudancaTabagismo: fr.pages[8].values[10],
      mudancaEtilismo: fr.pages[8].values[12],

      alimentacaoRefeicoes: fr.pages[4].values[3],
      alimentacaoFrutas: fr.pages[4].values[7],
      alimentacaoLaticionios: fr.pages[4].values[9],
      alimentacaoCarbo: fr.pages[4].values[11],
      alimentacaoCarnes: fr.pages[4].values[13],
      alimentacaoDoces: fr.pages[4].values[15],
      alimentacaoFastFood: fr.pages[4].values[17],
      alimentacaoEmbutidos: fr.pages[4].values[19],
      alimentacaoOleos: fr.pages[4].values[21],
      alimentacaoAgua: fr.pages[4].values[23] + "ml/dia",
      alimentacaoAguaInterpretacao: fr.pages[4].values[24],
      alimentacaoAutoavaliacao: fr.pages[2].values[0],
      alimentacaoAvalEqualime: fr.pages[2].values[1],
      alimentacaoPadroes: fr.pages[2].values[15] + "ml/dia",

      exercicioBaixa: fr.pages[10].values[35],
      exercicioModerada: fr.pages[10].values[36],
      exercicioAlta: fr.pages[10].values[37],
      exercicioMotivacao: fr.pages[5].values[4],
      exercicioBarreiras: fr.pages[5].values[5],
      exercicioVo2max: fr.pages[5].values[2],
      exercicioClassVo2max: fr.pages[5].values[3],
      exercicioNivel: fr.pages[1].values[2],
      exercicioCondCardio: fr.pages[1].values[3],
      exercicioCompIncomp: fr.pages[1].values[4],
      exercicioAvalEqualime: fr.pages[2].values[14],

      sonoTempo: fr.pages[10].values[42],
      sonoFreqAcorda: fr.pages[10].values[43],
      sonoCansado: fr.pages[10].values[44],
      sonoTomaAlgo: fr.pages[10].values[45],
      sonoAvaliacao: fr.pages[1].values[5],

      saudeMentalAnsioso: fr.pages[10].values[48],
      saudeMentalPreocupado: fr.pages[10].values[49],
      saudeMentalPrazer: fr.pages[10].values[50],
      saudeMentalDeprimido: fr.pages[10].values[51],
      saudeMentalProposito: fr.pages[10].values[52],
      saudeMentalAmigos: fr.pages[10].values[53],
      saudeMentalIndicioAnsiedade: fr.pages[7].values[0],
      saudeMentalIndicioDepressao: fr.pages[7].values[1],
      saudeMentalIndicioEstresse: fr.pages[7].values[2],
      saudeMentalIndicioProposito: fr.pages[7].values[3],
      saudeMentalIndicioConexoes: fr.pages[7].values[4],

      notaEqualime: fr.pages[0].values[0],
    };

    return frJson;
  }

  return (
    <>
      <Button onClick={() => exportToExcel()}>Exportar Excel</Button>
    </>
  );
};

export default ExportExcel;
