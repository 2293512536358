/* eslint-disable react/jsx-key */
import {useState, useEffect} from "react";
import axios from "axios";
import urls from "../../../../routes/api/apiRoutes";
import {useParams} from "react-router";
import styles from "./ReportColab.module.css";
import {Button} from "@material-ui/core";
const ReportColab = ({token}) => {
  // ===== TESTES =====
  // console.log(id);

  // axios.get(urls.getReportColab).then((response) => {
  //   console.log(response);
  // });
  // ==================

  const [reportData, setReportData] = useState(null);
  const [formData, setFormData] = useState(null);
  const {id} = useParams();

  useEffect(() => {
    if (!token) return null;
    console.log("token:  " + token);
    console.log("id:  " + id);

    const config = {
      headers: {
        "x-access-token": token,
      },
      params: {
        id: id,
      },
    };

    axios
      .get(urls.checkFormData, config)
      .then((responseFormData) => {
        setFormData(responseFormData.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(urls.getReport, config)
      .then((response) => {
        setReportData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, token]);

  if (reportData && formData) {
    // const respostas = reportData.pages[10];
    console.log(formData, "formData:");
    console.log(reportData, "finalReport");

    // Identificação
    const nome = reportData.pages[10].values[0];
    const idade = reportData.pages[3].values[2];

    // Dados Físicos
    const peso = reportData.pages[3].values[6];
    const altura = reportData.pages[3].values[7];
    const imc = reportData.pages[3].values[5];
    const cintura = reportData.pages[3].values[16];
    const cinturaEstatura = reportData.pages[3].values[17];
    const freqCardRepouso = reportData.pages[3].values[8];
    const vo2 = reportData.pages[5].values[2];

    // Resumo da avaliação e recomendações
    const notaEqualime = reportData.pages[0].values[0];
    const autoavaliacao_saude = reportData.pages[3].values[9];

    let estilodevida = "";
    let corr_equalime_autoavaliacaosaude = "";
    if (notaEqualime > 70) {
      estilodevida =
        "No geral, seu estilo de vida é adequado para uma boa saúde, mas sempre podemos melhorar.";

      // correlação NOTA EQUALIME e AUTO AVALIAÇÃO DE SAÚDE
      if (autoavaliacao_saude >= 8)
        corr_equalime_autoavaliacaosaude =
          "A avaliação que fizemos sobre a sua saúde está igual às suas expectativas.";
      else
        corr_equalime_autoavaliacaosaude =
          "A avaliação que fizemos sobre a sua saúde está acima das suas expectativas.";
    } else if (notaEqualime > 50 && notaEqualime <= 70) {
      estilodevida =
        "No geral, seu estilo de vida é regular. Vamos melhorar os pontos onde você apresenta maior risco para desenvolver doenças crônicas?";
      if (autoavaliacao_saude > 5 && autoavaliacao_saude < 8)
        corr_equalime_autoavaliacaosaude =
          "A avaliação que fizemos sobre a sua saúde está igual às suas expectativas.";
      else if (autoavaliacao_saude >= 8)
        corr_equalime_autoavaliacaosaude =
          "A avaliação que fizemos sobre a sua saúde está abaixo das suas expectativas.";
      else
        corr_equalime_autoavaliacaosaude =
          "A avaliação que fizemos sobre a sua saúde está acima das suas expectativas.";
    } else {
      estilodevida =
        "No geral, seu estilo de vida é inadequado para uma boa saúde. Sua saúde precisa de maior atenção. Mudanças nos seus hábitos de vida são importantes para reduzir o risco de desenvolver doenças crônicas e com isso melhorar e manter uma boa saúde.";
      if (autoavaliacao_saude <= 5)
        corr_equalime_autoavaliacaosaude =
          "A avaliação que fizemos sobre a sua saúde está igual às suas expectativas.";
      else
        corr_equalime_autoavaliacaosaude =
          "A avaliação que fizemos sobre a sua saúde está abaixo das suas expectativas.";
    }

    // ===============================================================================
    // HÁBITOS CONFIRMADOS
    // ===============================================================================
    let habitos_confirmados_cont = 0;
    let habitos_confirmados = [];

    // tabagismo
    if (formData.questions.find((a) => a.id == "cigarette").values[0] == 2) {
      habitos_confirmados_cont++;
      habitos_confirmados[habitos_confirmados_cont] =
        "ter deixado o hábito de fumar";
    } else if (
      formData.questions.find((a) => a.id == "cigarette").values[0] == 3
    ) {
      habitos_confirmados_cont++;
      habitos_confirmados[habitos_confirmados_cont] = "não fumar";
    }
    // etilismo
    if (formData.questions.find((a) => a.id == "alcohol").values[0] == 1) {
      habitos_confirmados_cont++;
      habitos_confirmados[habitos_confirmados_cont] =
        "baixo consumo de bebidas alcoólicas";
    }
    // peso
    if (
      formData.questions.find((a) => a.id == "corporalWeight").values[0] == 3 &&
      cinturaEstatura < 0.5
    ) {
      habitos_confirmados_cont++;
      habitos_confirmados[habitos_confirmados_cont] = "peso corporal";
    }
    // nutrição
    if (
      formData.questions.find((a) => a.id == "nutritionLevel").values[0] >= 5 &&
      reportData.pages[0].values[7] == "risco baixo"
    ) {
      habitos_confirmados_cont++;
      habitos_confirmados[habitos_confirmados_cont] = "alimentação";
    }
    // atividade física
    if (
      formData.questions.find((a) => a.id == "physicalActivityLevel")
        .values[0] >= 7 &&
      reportData.pages[0].values[4] == "risco baixo"
    ) {
      habitos_confirmados_cont++;
      habitos_confirmados[habitos_confirmados_cont] = "atividade física";
    }
    // sono
    if (
      formData.questions.find((a) => a.id == "sleepLevel").values[0] >= 9 &&
      reportData.pages[0].values[8] == "risco baixo"
    ) {
      habitos_confirmados_cont++;
      habitos_confirmados[habitos_confirmados_cont] = "sono";
    }
    // saúde mental
    if (
      formData.questions.find((a) => a.id == "mentalHealthLevel").values[0] >=
        8 &&
      reportData.pages[0].values[9] == "risco baixo"
    ) {
      habitos_confirmados_cont++;
      habitos_confirmados[habitos_confirmados_cont] = "saúde mental";
    }
    // ===============================================================================

    // ===============================================================================
    // HÁBITOS SURPRESA
    // ===============================================================================
    let habitos_surpresa_cont = 0;
    let habitos_surpresa = [];

    // peso
    if (
      formData.questions.find((a) => a.id == "corporalWeight").values[0] != 3 &&
      cinturaEstatura < 0.5
    ) {
      habitos_surpresa_cont++;
      habitos_surpresa[habitos_surpresa_cont] = "peso corporal";
    }
    // nutrição
    if (
      formData.questions.find((a) => a.id == "nutritionLevel").values[0] <= 4 &&
      reportData.pages[0].values[7] == "risco baixo"
    ) {
      habitos_surpresa_cont++;
      habitos_surpresa[habitos_surpresa_cont] = "alimentação";
    }
    // atividade física
    if (
      formData.questions.find((a) => a.id == "physicalActivityLevel")
        .values[0] <= 6 &&
      reportData.pages[0].values[4] == "risco baixo"
    ) {
      habitos_surpresa_cont++;
      habitos_surpresa[habitos_surpresa_cont] = "atividade física";
    }
    // sono
    if (
      formData.questions.find((a) => a.id == "sleepLevel").values[0] <= 8 &&
      reportData.pages[0].values[8] == "risco baixo"
    ) {
      habitos_surpresa_cont++;
      habitos_surpresa[habitos_surpresa_cont] = "sono";
    }
    // saúde mental
    // if (
    //   formData.questions.find((a) => a.id == "mentalHealthLevel").values[0] <=
    //     7 &&
    //   reportData.pages[0].values[9] == "risco baixo"
    // ) {
    //   habitos_surpresa_cont++;
    //   habitos_surpresa[habitos_surpresa_cont] = "saúde mental";
    // }

    // ===============================================================================
    // BANDEIRAS VERMELHAS E AMARELAS - prioridades
    // ===============================================================================

    // PesoCorporal
    let pilarPesoCorporal = {
      nome: "Peso Corporal",
      risco: reportData.pages[0].values[3],
      vontade: formData.questions.find((a) => a.id == "corporalWeight")
        .values[0],
      situacao: situacaoPeso(),
    };

    let pilares = [];

    // CondicionamentoCardiorrespiratorio
    let pilarCondicionamentoCardiorrespiratorio = {
      nome: "Condicionamento Cardiorrespiratório",
      risco: reportData.pages[0].values[4],
      prioridade: -1,
      mudancaEstiloVida: formData.questions.find(
        (a) => a.id == "improveLifeQualities"
      ).values[1],
      situacao: parseInt(
        formData.questions.find((a) => a.id == "physicalActivitySituation")
          .values[0]
      ),
      texto: "",
    };

    textoCardiorrespiratorio(pilarCondicionamentoCardiorrespiratorio);
    pilares.push(pilarCondicionamentoCardiorrespiratorio);

    // Tabagismo
    let pilarTabagismo = {
      nome: "Hábito de Fumar",
      risco: reportData.pages[0].values[5],
      prioridade: -1,
      mudancaEstiloVida: formData.questions.find(
        (a) => a.id == "improveLifeQualities"
      ).values[5],
      situacao: situacaoCigarro(),
      texto: "",
    };
    textoTabagismo(pilarTabagismo);
    if (pilarTabagismo.risco == "risco alto") {
      pilares.push(pilarTabagismo);
    }

    // ConsumoAlcool
    let pilarConsumoAlcool = {
      nome: "Consumo Álcool",
      risco: reportData.pages[0].values[6],
      prioridade: -1,
      mudancaEstiloVida: formData.questions.find(
        (a) => a.id == "improveLifeQualities"
      ).values[6],
      situacao: situacaoAlcool(),
      texto: "",
    };
    textoAlcool(pilarConsumoAlcool);
    pilares.push(pilarConsumoAlcool);

    // Nutricao
    let pilarNutricao = {
      nome: "Alimentação",
      risco: reportData.pages[0].values[7],
      prioridade: -1,
      mudancaEstiloVida: formData.questions.find(
        (a) => a.id == "improveLifeQualities"
      ).values[2],
      situacao: parseInt(
        formData.questions.find((a) => a.id == "nutritionSituation").values[0]
      ),
      texto: "",
    };
    textoNutricao(pilarNutricao);
    pilares.push(pilarNutricao);

    // Sono
    let pilarSono = {
      nome: "Sono",
      risco: reportData.pages[0].values[8],
      prioridade: -1,
      mudancaEstiloVida: formData.questions.find(
        (a) => a.id == "improveLifeQualities"
      ).values[0],
      situacao: parseInt(
        formData.questions.find((a) => a.id == "sleepSituation").values[0]
      ),
      texto: "",
    };
    textoSono(pilarSono);
    pilares.push(pilarSono);

    // SaudeMental
    let pilarSaudeMental = {
      nome: "Saúde Mental",
      risco: reportData.pages[0].values[9],
      prioridade: -1,
      mudancaEstiloVida: formData.questions.find(
        (a) => a.id == "improveLifeQualities"
      ).values[3],
      situacao: parseInt(
        formData.questions.find((a) => a.id == "mentalHealthSituation")
          .values[0]
      ),
      texto: "",
    };
    textoSaudeMental(pilarSaudeMental);
    pilares.push(pilarSaudeMental);

    if (pilares.filter((a) => a.risco == "risco alto").length > 0) {
      pilares
        .filter((a) => a.risco == "risco alto")
        .forEach((a) => (a.prioridade = 5));
    }
    if (pilares.filter((a) => a.risco == "risco moderado").length > 0) {
      pilares
        .filter((a) => a.risco == "risco moderado")
        .forEach((a) => (a.prioridade = 1));
    }

    pilares = pilares.filter((a) => a.prioridade !== -1);

    // if (pilares.length > 0) {
    //   pilares.sort(ordenarPorMudanca);
    // }
    // ordena as prioridades
    if (pilares.length > 0) {
      pilares.sort(ordenarPorPrioridades);
    }

    // filtra os 3 primeiros
    if (pilares.length > 3) {
      pilares = pilares.slice(0, 3);
    }

    console.log(pilares);

    // possui algum pilar com Risco Alto ou Moderado?
    let areasAtencao =
      pilares.length > 0 || pilarPesoCorporal.risco !== "risco baixo";

    // ===============================================================================
    // ===============================================================================
    // == RELATÓRIO ==================================================================
    // ===============================================================================
    // ===============================================================================
    return (
      <div className={styles.body}>
        <Button id="btnImprimir" onClick={() => printDocument()}>
          IMPRIMIR
        </Button>

        <div id="relatorio-colaborador">
          <h1>Relatório Colaborador</h1>

          <h2>1. Identificação:</h2>
          {/* <p>ID: id</p> */}

          <p>
            <strong>Nome:</strong> {nome}{" "}
          </p>
          <p>
            <strong>Idade:</strong> {idade}
            {" anos"}
          </p>

          <h2>2. Dados Físicos</h2>
          <p>
            <strong>Peso:</strong> {peso}
            {" kg"}
          </p>
          <p>
            <strong>Altura:</strong> {altura}
            {" m"}
          </p>
          <p>
            <strong>Índice de massa corporal (IMC):</strong> {imc}
            {" kg/m²"}
          </p>
          <p>
            <strong>Cintura:</strong> {(cintura * 100).toFixed(2)}
            {" cm"}
          </p>
          <p>
            <strong>Cintura/Altura:</strong>{" "}
            {Number(cinturaEstatura).toFixed(2)}{" "}
          </p>
          <p>
            <strong>Frequência cardíaca de repouso:</strong> {freqCardRepouso}
            {" bpm"}
          </p>
          <p>
            <strong>Consumo máximo de oxigênio (VO2) estimado:</strong> {vo2}{" "}
            mL/(kg·min)
          </p>

          <h2>3. Resumo da avaliação e recomendações</h2>
          <p>
            A sua nota de saúde segundo a EqualiMe é{" "}
            <strong>{notaEqualime}.</strong>
          </p>

          <p>{estilodevida}</p>

          <p>{corr_equalime_autoavaliacaosaude}</p>

          {habitos_confirmados_cont > 0 ? (
            <p>
              Conforme você imaginava, seus hábitos nas áreas abaixo estão
              adequados, ajudando a reduzir o risco para doenças crônicas:
            </p>
          ) : (
            ""
          )}

          <Lista topicos={habitos_confirmados} />

          {habitos_surpresa_cont > 0 ? (
            <p>
              Porém, há hábitos que você considera que não estão adequados e,
              para sua surpresa, baseado em estudos científicos internacionais,
              você está bem, como:
            </p>
          ) : (
            ""
          )}

          <Lista topicos={habitos_surpresa} />

          {areasAtencao == true ? (
            <p>No entanto, há áreas que precisam de mais atenção:</p>
          ) : (
            ""
          )}

          {pilarPesoCorporal.risco != "risco baixo" ? (
            <h3>Peso/Adiposidade Corporal</h3>
          ) : (
            ""
          )}

          {/* QUER PERDER PESO */}
          {pilarPesoCorporal.risco != "risco baixo" &&
          pilarPesoCorporal.vontade >= 4 ? (
            <>
              <p>
                O acúmulo de gordura na região abdominal está relacionado com
                inúmeras alterações metabólicas e favorece o surgimento de
                várias doenças crônicas, como doenças cardiovasculares e
                diabetes. Mudanças relacionadas ao estilo de vida são
                indispensáveis para ajudar você a melhorar a sua composição
                corporal e permanecer com o peso adequado a longo prazo. Uma
                alimentação equilibrada e um estilo de vida ativo são muito
                importantes, mas cuidados com o sono e o controle do estresse
                também são cruciais nesse processo.
              </p>
              <p>
                Inclua novos hábitos no seu dia a dia e não tenha pressa para
                atingir o seu objetivo final. Se tiver dúvida por onde começar,
                sugerimos que você comece focando na prática de atividade
                física. O exercício é o que chamamos de hábito mestre, ou seja,
                quando conseguimos incluí-lo na nossa vida, modificamos
                naturalmente outros hábitos.
              </p>
              <p>
                De qualquer forma, como o excesso gordura na região abdominal
                está relacionado a uma série de doenças crônicas, é importante
                que você procure um médico ou nutricionista para fazer uma
                avaliação mais detalhada da sua saúde.
              </p>
            </>
          ) : (
            ""
          )}

          {pilarPesoCorporal.risco != "risco baixo" &&
          pilarPesoCorporal.vontade < 4 ? (
            <>
              <p>
                O acúmulo de gordura na região abdominal está relacionado com
                inúmeras alterações metabólicas, mesmo em pessoas que não
                estejam com excesso de peso. Isso acontece porque o excesso de
                gordura nessa região pode levar a um quadro de inflamação
                crônica, desregulando o metabolismo e aumentando o risco de
                obesidade, doenças cardíacas, AVC, resistência à insulina,
                diabetes, entre outras doenças crônicas não transmissíveis.
              </p>
              <p>
                O acúmulo de gordura nessa região pode ser desencadeado por
                vários fatores relacionados ao estilo de vida, como alimentação
                inadequada, pouca atividade física, estresse e sono ruim. Porém,
                questões hormonais e predisposição genética também podem ter uma
                grande influência.
              </p>
              <p>
                Evitar o excesso de gordura na região abdominal é muito mais do
                que uma busca estética, sendo um fator indispensável para a
                melhora da saúde física e mental. Adote um estilo de vida ativo,
                faça boas escolhas alimentares e incorpore no dia a dia
                estratégias para gerenciar o estresse e melhorar o sono.
              </p>
              <p>
                Procure um médico ou nutricionista para te ajudar nessa jornada!
              </p>
            </>
          ) : (
            ""
          )}

          {pilares.length > 0 ? (
            <>
              <h3>{pilares[0].nome}</h3>
              <div dangerouslySetInnerHTML={{__html: pilares[0].texto}} />
            </>
          ) : (
            ""
          )}

          {pilares.length > 1 ? (
            <>
              <h3>{pilares[1].nome}</h3>
              <div dangerouslySetInnerHTML={{__html: pilares[1].texto}} />
            </>
          ) : (
            ""
          )}

          {pilares.length > 2 ? (
            <>
              <h3>{pilares[2].nome}</h3>
              <div dangerouslySetInnerHTML={{__html: pilares[2].texto}} />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.body}>
        <h1>Relatório Colaborador</h1>

        <p>Não encontrado</p>
      </div>
    );
  }

  function Lista(props) {
    if (props.topicos.length == 0 && props.topicos.length == null) return "";

    const listItems = props.topicos.map((item) => <li key={item}>{item}</li>);

    return <ul>{listItems}</ul>;
  }

  function ordenarPorPrioridades(a, b) {
    // vermelho
    if (a.prioridade === b.prioridade) {
      // check para mudança do pilar
      if (a.mudancaEstiloVida === b.mudancaEstiloVida) {
        // ação, manutenção ou preparação
        return b.situacao - a.situacao;
      } else if (a.mudancaEstiloVida) {
        return -1;
      } else {
        return 1;
      }
    } else {
      return b.prioridade - a.prioridade;
    }
  }

  // texto cardiorrespiratório
  function textoCardiorrespiratorio(pilar) {
    // pré ou contemplação
    let texto = "";
    let compatibilidade = "";
    reportData.pages[1].values[4] == "compatível"
      ? (compatibilidade = "compatibilidade")
      : (compatibilidade = "incompatibilidade");
    if (pilar.situacao < 3) {
      texto +=
        "<p>Seu nível de atividade física é " +
        reportData.pages[2].values[14] +
        " e seu condicionamento cardiorrespiratório " +
        reportData.pages[1].values[3] +
        ".</p>";
      texto += "<p>Há " + compatibilidade + " entre ambos.</p>";
      texto +=
        "<p>A prática regular de atividade física pode trazer muitos benefícios. Além de prevenir diabetes, câncer e doenças cardiovasculares, também promove o bem-estar físico e mental, aumenta o condicionamento cardiorrespiratório, reduz o estresse, aumenta a energia, melhora a qualidade do sono, melhora o humor, auxilia o controle do peso corporal, fortalece os músculos e ossos, aumenta a autoconfiança e reduz dor crônica.</p>";
      texto +=
        "<p>Como se convencer dos benefícios de algo quando você ainda não conseguiu experimentá-los? Na primeira semana, calce seu tênis e saia, tendo como meta fazer alguma atividade física por apenas 15 minutos.</p>";
      texto +=
        "<p>Feito é melhor que perfeito, para começar qualquer quantidade já faz diferença.</p>";
      texto +=
        "<p>É provável que, uma vez começado, você consiga manter uma rotina.</p>";

      if (
        !formData.questions.find((a) => a.id == "problemsToExercise").values[0]
      ) {
        // tempo
        if (
          formData.questions.find((a) => a.id == "problemsToExercise").values[3]
        )
          texto +=
            "<p>Utilize os seus deslocamentos pela cidade para caminhar, aproveite os seus intervalos para se movimentar, troque o elevador por alguns lances de escada e evite ficar sentado muitas horas na frente do computador. Experimente atividades curtas. Em vez de caminhar continuamente por 40 minutos, você pode fazer 2 caminhadas de 20 minutos ao longo do dia.</p>";

        // indisciplina
        if (
          formData.questions.find((a) => a.id == "problemsToExercise").values[7]
        )
          texto +=
            "<p>Defina metas. Programe-se, estabeleça um horário para a prática de atividade física e organize com antecedência os lanches e a mochila com roupas adequadas, se necessário.</p>";

        // cansaço/falta de energia ou psicológica
        if (
          formData.questions.find((a) => a.id == "problemsToExercise")
            .values[1] ||
          formData.questions.find((a) => a.id == "problemsToExercise").values[6]
        )
          texto +=
            "<p>Por incrível que pareça, fazer atividade física irá aumentar a sua energia. Procure se exercitar em horários que você esteja mais disposto e perceba como o seu organismo reagiu, como você terá mais vontade de fazer as outras atividades. Outra possibilidade, é quando identificar que está mais desanimado ou irritado, procurar movimentar-se por pelo menos 15 minutos. Não se esqueça de verificar como está a sua alimentação antes dos exercícios!</p>";

        // LESÕES ARTICULARES ou DOR MUSCULAR LIMITANTE ou LIMITAÇÕES FÍSICAS PARA REALIZAR SUAS ATIVIDADES DIÁRIAS
        if (
          formData.questions.find((a) => a.id == "problemsToExercise")
            .values[8] ||
          formData.questions.find((a) => a.id == "problemsToExercise")
            .values[9] ||
          formData.questions.find((a) => a.id == "problemsToExercise")
            .values[12]
        )
          texto +=
            "<p>Essa é uma preocupação legítima. Mas, se começar aos poucos, não há motivo para se preocupar, em especial se a modalidade de exercício escolhida for moderada, como uma caminhada. O exercício bem aplicado, ajuda a reduzir a dor e a inflamação. Procure acompanhamento médico, comece aos poucos, opte por exercícios de baixa intensidade e evite esforços com dor. Seguindo esses cuidados você será premiado com os benefícios da atividade física.</p>";

        // roupas apropriadas
        if (
          formData.questions.find((a) => a.id == "problemsToExercise").values[5]
        )
          texto +=
            "<p>Adotar uma rotina regular de exercícios não significa ter a necessidade de gastar com roupas ou tênis caros. Para iniciar, qualquer roupa confortável pode ser usada.</p>";

        // custo
        if (
          formData.questions.find((a) => a.id == "problemsToExercise").values[2]
        )
          texto +=
            "<p>Você não precisa se matricular em uma academia para iniciar a prática de atividade física. A caminhada não envolve custos e, como pode ser praticada nos trajetos do trabalho, interfere pouco na sua rotina laboral.</p>";

        // não gosta
        if (
          formData.questions.find((a) => a.id == "problemsToExercise").values[4]
        )
          texto +=
            "<p>Procure combinar com amigos de fazer atividades físicas juntos. Dessa forma você se distrai e nem perceberá o tempo passar. Escolha a modalidade que mais tenha a ver com você. Obedeça seus gostos pessoais. Veja se consegue fazer com que seu parceiro ou outro membro de sua família se inscreva em alguma atividade com você ou o acompanhe em uma caminhada regular à noite. Reservar 20 minutos pela manhã ou à noite para andar de bicicleta, nadar ou caminhar com familiares vai contribuir para o bem-estar de todos e aproximá-los como família.</p>";

        // dificuldade respiratória
        if (
          formData.questions.find((a) => a.id == "problemsToExercise")
            .values[10]
        )
          texto +=
            "<p>Você não precisa se exercitar por longos períodos, divida em intervalos de 10 minutos, com intensidade baixa. Com isso, você conseguirá aumentar gradualmente o seu condicionamento cardiorespiratório.</p>";

        // PROBLEMAS DE EQUILÍBRIO E QUEDAS
        if (
          formData.questions.find((a) => a.id == "problemsToExercise")
            .values[10]
        )
          texto +=
            "<p>Para se sentir mais seguro, faça atividades na água (hidroginástica, natação) ou bicicleta ergométrica.</p>";

        // Falta de local adequado
        if (
          formData.questions.find((a) => a.id == "problemsToExercise")
            .values[13]
        )
          texto +=
            "<p>Ao longo do dia, aproveite cada oportunidade para se movimentar (use escadas, troque o carro/ônibus por bicicleta ou caminhada). Além disso, você pode também fazer exercícios em casa.</p>";
      }

      // preparação, ação ou manutenção
    } else {
      texto +=
        "<p>Seu nível de atividade física é " +
        reportData.pages[2].values[14] +
        " e seu condicionamento cardiorrespiratório " +
        reportData.pages[1].values[3] +
        ".</p>";
      texto += "<p>Há " + compatibilidade + " entre ambos.</p>";
      texto +=
        "<p>Você deve " +
        reportData.pages[9].values[23] +
        " o nível de atividade física para " +
        reportData.pages[9].values[24] +
        " o condicionamento cardiorrespiratório.</p>";
      texto +=
        "<p>A Organização Mundial de Saúde preconiza realizarmos atividade física moderada ou intensa de 150 a 300 minutos por semana. Para que a sua atividade física seja moderada, recomendamos que</p>";
      texto +=
        "<p>Para que a sua caminhada ou corrida diária fique numa intensidade moderada recomendamos:</p>";
      texto += "<ul>";
      texto +=
        "<li>A  distância deve estar entre " +
        reportData.pages[9].values[11] +
        " e " +
        reportData.pages[9].values[12] +
        " km</li>";
      texto +=
        "<li>A velocidade da caminhada deve estar entre " +
        reportData.pages[9].values[7] +
        " e " +
        reportData.pages[9].values[8] +
        " km/h</li>";
      texto +=
        "<li>Evitar caminhar acima de 6 Km/h e correr abaixo de 8 Km/h (sobrecarga mecânica)</li>";
      texto +=
        "<li>A frequência cardíaca deve estar entre " +
        reportData.pages[9].values[5] +
        " e " +
        reportData.pages[9].values[6] +
        " bpm</li>";
      texto +=
        "<li>Caso não consiga medir a sua frequência cardíaca ou a sua velocidade, isso corresponderia a caminhar ou correr entre " +
        reportData.pages[9].values[6] +
        " e " +
        reportData.pages[9].values[10] +
        " passos por minuto, num total de " +
        reportData.pages[9].values[13] +
        " e " +
        reportData.pages[9].values[14] +
        " passos</li>";
      texto += "</ul>";
      texto +=
        "<p>Por onde você vai começar? Não se esqueça de fazer um diário das suas atividades, para você conseguir visualizar sua progressão!</p>";
    }
    pilar.texto = texto;
  }

  function textoTabagismo(pilar) {
    // pré ou contemplação
    let texto = "";
    if (pilar.situacao < 3) {
      texto +=
        "<p>Parar de fumar sempre vale a pena em qualquer momento da vida.</p>";
      texto +=
        "<p>Quanto mais cedo você parar de fumar, menor o risco de ter uma doença associada.</p>";
      texto += "<p>Se você parar de fumar agora:</p>";
      texto += "<ul>";
      texto +=
        "<li>Após 20 minutos, a pressão sanguínea e a pulsação voltam ao normal.</li>";
      texto +=
        "<li>Após 2 horas, não há mais nicotina circulando no sangue.</li>";
      texto +=
        "<li>Após 8 horas, o nível de oxigênio no sangue se normaliza.</li>";
      texto += "<li>Após 12 a 24 horas, os pulmões já funcionam melhor.</li>";
      texto +=
        "<li>Após 2 dias, o olfato já percebe melhor os cheiros e o paladar já degusta melhor a comida.</li>";
      texto +=
        "<li>Após 3 semanas, a respiração se torna mais fácil e a circulação melhora.</li>";
      texto +=
        "<li>Após 1 ano, o risco de morte por infarto do miocárdio é reduzido à metade.</li>";
      texto +=
        "<li>Após 10 anos, o risco de sofrer infarto será igual ao das pessoas que nunca fumaram<br /> (Adaptado de www.inca.gov.br/programa-nacional-de-controle-do-tabagismo)</li>";
      texto += "</ul>";
      texto +=
        "<p>Você pode encontrar dicas práticas de como parar de fumar aqui:";
      texto +=
        '<br /><a href="https://www.inca.gov.br/sites/ufu.sti.inca.local/files//media/document//voce-quer-parar-de-fumar.pdf" target="_blank">https://www.inca.gov.br/sites/ufu.sti.inca.local/files//media/document//voce-quer-parar-de-fumar.pdf</a></p>';
    } else {
      texto +=
        "<p>Parar de fumar sempre vale a pena em qualquer momento da vida.</p>";
      texto +=
        "<p>Quanto mais cedo você parar de fumar, menor o risco de ter uma doença associada.</p>";
      texto += "<ul>";
      texto +=
        "<li>Se você quer parar de fumar comece escolhendo uma data para ser o seu primeiro dia sem cigarro. Este dia não precisa ser um dia de sofrimento. Faça dele uma ocasião especial e procure programar outra coisa que goste de fazer para se distrair e relaxar.</li>";
      texto +=
        "<li>Uma segunda opção é tentar reduzir o número de cigarros gradualmente em duas semanas.<br />Contar o número de cigarros fumados por dia.<br />Passar a fumar um número menor a cada dia.<br />Adiar a hora em que começa a fumar o primeiro cigarro do dia, aumentando o número de horas a cada dia, até chegar o dia em que você não fumará nenhum cigarro.</li>";
      texto +=
        "<li>Procure não falar sobre o hábito de fumar, para não lembrá-lo do desejo. <br />(Adaptado de www.inca.gov.br/programa-nacional-de-controle-do-tabagismo)</li>";
      texto += "</ul>";
      texto +=
        "<p>Você pode encontrar dicas práticas de como parar de fumar aqui:";
      texto +=
        '<br /><a href="https://www.inca.gov.br/sites/ufu.sti.inca.local/files//media/document//voce-quer-parar-de-fumar.pdf" target="_blank">https://www.inca.gov.br/sites/ufu.sti.inca.local/files//media/document//voce-quer-parar-de-fumar.pdf</a></p>';
      texto +=
        "<p>Você pode enfrentar esse desafio sozinho, e muitas pessoas conseguem vencer.</p>";
      texto +=
        "<p>Mas também pode pedir ajuda, já que hoje existem diferentes estratégias e abordagens.  É bom conversar sobre isso com o seu médico.</p>";
    }

    pilar.texto += texto;
  }

  function textoAlcool(pilar) {
    // pré ou contemplação
    let texto = "";

    if (pilar.risco == "risco alto") {
      if (pilar.situacao < 3) {
        texto +=
          "<p>Embora seja aceito socialmente, o consumo regular de álcool (três ou mais copos/taças por semana) está associado a um risco maior de desenvolver doenças crônicas, além de algum grau de dependência.</p>";
        texto +=
          "<p>O ideal para sua saúde é que você não consuma álcool, ou que seja um consumidor ocasional. </p>";
        texto +=
          "<p>Com a interrupção do consumo, evitamos o desenvolvimento de doenças crônicas, incluindo hipertensão arterial, doença cardíaca, acidente vascular cerebral, doença hepática e problemas digestivos e de saúde mental causados pelo álcool, incluindo depressão e ansiedade.</p>";
        texto +=
          "<p>Além disso, parar de beber economiza dinheiro, melhora o sono, a saúde geral e da pele, a disposição, a composição corporal e aprimora a concentração.</p>";
        texto +=
          "<p>Você já parou para pensar nas vantagens que parar de beber trariam para a sua vida? Que tal fazer uma lista com o que você ganharia e o que você perderia se você parasse de beber? Listar é uma forma de motivá-lo para visualizar os próximos passos.</p>";
        texto +=
          "<p>Desenvolva atividades que sejam prazerosas, mas que não envolvam o consumo de bebidas alcoólicas. </p>";
        texto +=
          "<p>Caso considere um desafio muito grande para vencer sozinho, você pode procurar ajuda médica, incluindo de psiquiatra.</p>";
      } else {
        texto +=
          "<p>Embora seja aceito socialmente, o consumo regular de álcool (três ou mais copos/taças por semana) está associado a um risco maior de desenvolver doenças crônicas, além de algum grau de dependência.</p>";
        texto +=
          "<p>O ideal para sua saúde é que você não consuma álcool, ou que seja um consumidor ocasional.</p>";
        texto +=
          "<p>Caso você tenha decidido parar de beber, não adie. Defina uma data e comece! </p>";
        texto +=
          "<p>Compartilhe sua intenção de parar. Isso poderá fazer com que seus familiares e amigos se mobilizem para apoiá-lo.</p>";
        texto +=
          "<p>Afaste-se do que possa lembrá-lo do uso da substância ou desencadear uma vontade de beber, como bares, lojas de bebidas ou o setor de bebidas de um mercado.</p>";
        texto +=
          "<p>Não se esqueça de que ter hábitos saudáveis também irá ajudá-lo nesse processo: alimente-se e durma bem, pratique atividade física, beba água, tenha um hobby e procure fazer atividades sociais com pessoas que não consumam bebida alcoólica.</p>";
        texto +=
          "<p>Caso considere um desafio muito grande para vencer sozinho, você pode procurar ajuda médica, incluindo de psiquiatra.</p>";
      }
    } else {
      texto +=
        "<p>Embora seja aceito socialmente, o consumo regular de álcool (três ou mais copos/taças por semana) está associado a um risco maior de desenvolver doenças crônicas.</p>";
      texto +=
        "<p>O ideal para sua saúde é que você não consuma álcool, ou que seja um consumidor ocasional. </p>";
      texto +=
        "<p>Você já parou para pensar nas vantagens que parar de beber trariam para a sua vida? Que tal fazer uma lista com o que você ganharia e o que você perderia se você parasse de beber? Listar é uma forma de motivá-lo para visualizar os próximos passos.</p>";
      texto +=
        "<p>Desenvolva atividades que sejam prazerosas, mas que não envolvam o consumo de bebidas alcoólicas. </p>";
      texto +=
        "<p>Não se esqueça de que ter hábitos saudáveis também irá ajudá-lo nesse processo: alimente-se e durma bem, pratique atividade física, beba água, tenha um hobby e procure fazer atividades sociais com pessoas que não consumam bebida alcoólica.</p>";
      texto +=
        "<p>Caso considere um desafio muito grande para vencer sozinho, você pode procurar ajuda médica, incluindo de psiquiatra.</p>";
    }

    pilar.texto += texto;
  }

  function textoNutricao(pilar) {
    // pré ou contemplação
    let texto = "";

    if (pilar.situacao < 3) {
      texto +=
        "<p>A alimentação saudável é um importante fator para a manutenção da saúde e prevenção de doenças crônicas não transmissíveis, incluindo diabetes, doenças cardiovasculares e alguns tipos de câncer. </p>";
      texto +=
        "<p>Você sabe o que é ter uma alimentação saudável? Ao contrário do que muitas pessoas imaginam, possuir uma alimentação saudável não significa fazer várias restrições alimentares. Para ter uma alimentação adequada e saudável, que forneça todos os nutrientes que seu corpo precisa, é preciso pensar em equilíbrio, variedade, quantidade e na segurança dos alimentos consumidos.</p>";
      texto +=
        "<p>Consumir alimentos <em>in natura</em> ou pouco processados, evitar o consumo de alimentos ultraprocessados, usar pouco sal, açúcar e óleo no preparo dos alimentos, montar pratos com verduras e legumes de cores diferentes, beber bastante água ao longo do dia e não pular refeições são ações que fazem parte de hábitos alimentares saudáveis.</p>";
      texto +=
        "<p>Os hábitos que constituem uma alimentação saudável são simples, mas com os dias cada vez mais corridos e o pouco tempo que deixamos disponível para as refeições, os cuidados com a alimentação podem ficar em segundo plano no dia a dia.   </p>";
      texto +=
        "<p>Percebemos que mudanças na alimentação ainda não estão entre as suas prioridades. Que tal escolher uma das três opções abaixo para começar a cuidar dessa área?</p>";
      texto += "<ol>";
      texto +=
        "<li>Beba pelo menos " +
        (Math.round(((reportData.pages[9].values[4] * 0.9) / 1000) * 10) / 10)
          .toString()
          .replace(".", ",") +
        " litros de água ao longo do dia.</li>";
      texto +=
        "<li>Esteja presente no momento das suas refeições. Sente-se à mesa para se alimentar, sinta o sabor e o aroma dos alimentos, perceba os sinais do seu corpo (se está com fome ou se está ficando saciado) e não foque em distrações como celular, televisão ou computador.</li>";
      texto +=
        "<li>Coma comida de verdade, dando preferência aos alimentos menos industrializados como: frutas, verduras, legumes, arroz, feijão, ovos, carnes magras e laticínios. Quanto mais você descascar e menos desembalar seus alimentos, mais saudável você ficará!</li>";
      texto += "</ol>";
      texto +=
        "<p>Se quiser aprender um pouco mais sobre alimentação saudável, dê uma olhada no Guia Alimentar para a População Brasileira, elaborado pelo Ministério da Saúde:";
      texto +=
        '<br /><a href="https://bvsms.saude.gov.br/bvs/publicacoes/guia_alimentar_populacao_brasileira_2ed.pdf" target="_blank">https://bvsms.saude.gov.br/bvs/publicacoes/guia_alimentar_populacao_brasileira_2ed.pdf</a></p>';
    } else {
      if (reportData.pages[4].values[1].length > 3)
        texto +=
          "<p>Parabéns, verificamos que você possui alguns hábitos bons como: " +
          reportData.pages[4].values[1] +
          ".</p>";

      if (reportData.pages[4].values[2].length > 3) {
        texto +=
          "<p>Mas, apesar disso, os seus hábitos alimentares precisam melhorar para colaborar com a sua saúde.</p>";
        texto +=
          "<p>Para a redução do risco de doenças crônicas e melhora da qualidade de vida é importante você " +
          reportData.pages[9].values[3] +
          ".</p>";
      } else {
        texto += "<p>Mantenha os seus hábitos alimentares atuais.</p>";
      }
      texto +=
        "<p>Recomenda-se que você consuma diariamente " +
        (Math.round(((reportData.pages[9].values[4] * 0.9) / 1000) * 10) / 10)
          .toString()
          .replace(".", ",") +
        " litros de água.</p>";
      texto +=
        "<p>Que tal escolher primeiro uma das opções acima para começar a cuidar dessa área?</p>";
    }

    pilar.texto += texto;
  }

  function textoSono(pilar) {
    let texto = "";

    // pré ou contemplação
    if (pilar.situacao < 3) {
      texto +=
        "<p>Sono é prioridade, são horas de descanso necessário. É durante o sono que ocorre a faxina cerebral, que permite que o cérebro funcione bem no dia seguinte. Você gostaria de trabalhar numa rua depois de uma feira, sem a lavagem da rua que acontece no final da feira? Não obrigue o seu cérebro a trabalhar desse jeito.</p>";
      texto += "<p>As horas de sono não são negociáveis.</p>";
      texto +=
        "<p>A falta de sono pode levar a alterações do humor, da memória e da saúde de forma geral. </p>";
      texto +=
        "<p>O pior, é que a falta de sono pode alterar estar alterando como você enxerga a situação, de uma forma que você não perceba o prejuízo que dormir mal está fazendo para a sua vida.</p>";
      texto += "<p>Você sabia que dormir pouco:</p>";
      texto += "<ul>";
      texto +=
        "<li>aumenta o apetite para doces e fast food, por alterar os hormônios. Dormir menos de 5 horas aumenta em 50% o risco de obesidade e triplica o risco de diabetes</li>";
      texto +=
        "<li>envelhece o seu cérebro de 3 a 5 anos, aumentando o risco para demência, irritabilidade, ansiedade, perda de memória e pensamento confuso. Dormir menos de 5 horas aumenta em 33% o risco de demência.</li>";
      texto +=
        "<li>aumenta o risco de desenvolver doenças cardiovasculares em quase 50%, sendo a mais comum, a hipertensão.</li>";
      texto +=
        "<li>reduz a atividade do sistema imune, triplicando a chance de você pegar resfriado ou gripe (aqueles resfriados que derrubam) e aumentando o risco para alguns tumores (36% para o câncer de intestino).</li>";
      texto += "</ul>";
    } else {
      texto +=
        "<p>É hora de fazer algo para reverter essa condição de dormir pouco, já que a falta de sono leva a alterações de humor, memória, atenção, aumenta o apetite e a chance de você desenvolver doenças infecciosas, cardiovasculares e alguns tumores.</p>";
    }

    texto +=
      "<p>Experimente mudar alguns de seus hábitos e verifique quais são as repercussões no seu corpo:</p>";
    texto += "<ol>";
    texto += "<li>Tenha uma rotina pré-sono</li>";
    texto +=
      "<li>Evite os distratores do sono, 1 hora antes de deitar-se:</li>";
    texto += "<ul>";
    texto += "<li>telas (TV, internet) e fonte de luz</li>";
    texto += "<li>discussões ou preocupações com assuntos tensos</li>";
    texto += "<li>alimentação pesada no jantar</li>";
    texto += "<li>ingestão de alimentos com cafeína ou álcool.</li>";
    texto += "</ul>";
    texto += "<li>Use facilitadores do sono antes de deitar-se: </li>";
    texto += "<ul>";
    texto +=
      "<li>bebida quente (chá sem cafeína) e alimentos com triptofano (banana, aveia, abacate, leite).</li>";
    texto += "<li>banho morno</li>";
    texto += "<li>deixe o quarto escuro e quieto</li>";
    texto += "<li>faça relaxamento com pensamentos de gratidão e medite</li>";
    texto += "</ul>";
    texto += "<li>Durante o dia: </li>";
    texto += "<ul>";
    texto += "<li>faça exercícios físicos</li>";
    texto += "<li>tome um pouco de sol nos horários adequados</li>";
    texto +=
      "<li>coma alimentos saudáveis em porções pequenas, distribuídas ao longo do dia</li>";
    texto += "</ul>";
    texto += "</ol>";

    if (pilar.risco == "risco alto") {
      texto +=
        "<p>É possível que você já tenha tentado colocar em prática algumas dessas sugestões. </p>";
      texto += "<p>Caso não tenha tentado alguma, experimente. </p>";
      texto +=
        "<p>Se não sentir alteração, você pode se beneficiar de uma consulta e acompanhamento médico especializado.</p>";
    }

    console.log(pilar);

    pilar.texto += texto;
  }

  function textoSaudeMental(pilar) {
    let texto =
      "<p>Ter uma boa saúde mental significa viver um estado de bem estar, que possibilita o desenvolvimento de suas habilidades pessoais para responder aos desafios da vida, aprender, trabalhar e contribuir para a melhoria da comunidade, pelo conceito da OMS. Esse estado resulta da interação de fatores biológicos, psicológicos e sociais e se reflete em todos os aspectos da sua vida, assim como no relacionamento com outras pessoas.</p>";
    texto +=
      "<p>Todos os indivíduos têm um arsenal de ferramentas internas para lidar com as situações adversas. Não existe uma vida sem estresse. Infelizmente, muitas vezes está fora do nosso controle a tentativa de reduzir as situações difíceis. Existem pessoas que conseguem lidar de uma forma menos estressante com situações desafiadoras do dia-a-dia. Há algumas dicas de como aumentar o seu arsenal de ferramentas. </p>";

    if (pilar.situacao < 3) {
      texto +=
        "<p>Mas, o mais importante é identificar se você está precisando investir nisso.</p>";
      texto +=
        "<p>Merecem mais atenção à saúde mental as pessoas com sofrimento, incapacidade funcional ou risco de comportamento lesivo, geralmente causados por depressão, ansiedade, esquizofrenia, transtorno afetivo bipolar, transtorno obsessivo-compulsivo e uso crônico de substâncias psicoativas e drogas, entre-outros. No entanto, há situações estressantes que quando vivenciadas cronicamente podem levar o indivíduo a situações disfuncionais. É importante saber reconhecer quando estamos entrando em uma rota que nos leva a reações mal adaptadas à realidade. </p>";
      texto +=
        "<p>Há pessoas que concentram seus esforços em trabalhar, ignorando o acolhimento que traz o convívio com pessoas agradáveis. Com a cabeça cheia não pensamos direito, quem trabalha estressado tem uma produtividade menor do que poderia alcançar!</p>";
      texto +=
        "<p>Se está com dúvidas, procure conversar mais com amigos, familiares, pessoas do seu convívio, para ajudá-lo a identificar se as suas reações têm sido compatíveis com as demandas. Essas pessoas próximas e que se preocupam com o seu bem estar podem ajudá-lo com algumas dicas. Caso as dicas de pessoas de seu convívio não sejam suficientes, é sempre possível procurar pessoas com mais experiência na sua comunidade, ou então profissionais da área como psicólogos, terapeutas, ou ainda psiquiatras.</p>";
    } else {
      texto += "<ol>";
      texto +=
        "<li>Comece fazendo pausas durante o dia, entre as suas atividades. <br />Faça a experiência de reservar um tempo para relaxar. Num primeiro momento, pode parecer estranho, mas é questão de costume, ou seja , amanhã será melhor do que hoje, e hoje já foi melhor que ontem.  </li>";
      texto +=
        "<li>Evite sobrecarga no trabalho. Planeje suas atividades verificando se é possível fazer no tempo estimado. Caso não seja, reorganize os prazos.</li>";
      texto +=
        "<li>Reserve tempo para o autocuidado, além da prática de atividade física e cuidado com a alimentação para aumentar ainda mais o bem estar.</li>";
      texto +=
        "<li>Relembre os pontos positivos do dia que passou, antes de deitar-se. Verifique se estão em concordância com a contribuição que gostaria de deixar para este mundo. </li>";
      texto +=
        "<li>Aproxime-se das pessoas, já que pedir ajuda não significa fraqueza, mas sim sabedoria de conhecer seus próprios limites. Busque apoio inicialmente na rede de amigos, familiares, pessoas próximas da sua comunidade. No caso de não ser efetivo, buscar ajuda de especializada, como terapia psicológica.</li>";
      texto += "</ol>";
    }

    pilar.texto = texto;
  }

  function situacaoCigarro() {
    if (formData.questions.find((a) => a.id == "cigarette").values[0] == 1) {
      return formData.questions.find((a) => a.id == "cigaretteSituation")
        .values[0];
    } else {
      return 0;
    }
  }
  function situacaoAlcool() {
    if (
      formData.questions.find((a) => a.id == "alcoholSituation") != undefined
    ) {
      return formData.questions.find((a) => a.id == "alcoholSituation")
        .values[0];
    } else {
      return 0;
    }
  }
  function situacaoPeso() {
    if (
      formData.questions.find((a) => a.id == "weightSituation") != undefined
    ) {
      return formData.questions.find((a) => a.id == "weightSituation")
        .values[0];
    } else {
      return 0;
    }
  }
  function printDocument() {
    // var mywindow = window.open("", "PRINT", "height=400,width=600");

    // mywindow.document.write(
    //   "<html><head><title>" + document.title + "</title>"
    // );
    // mywindow.document.write("</head><body >");
    // mywindow.document.write(document.getElementById(documentId).innerHTML);
    // mywindow.document.write("</body></html>");

    // mywindow.document.close(); // necessary for IE >= 10
    // mywindow.focus(); // necessary for IE >= 10*/

    window.print();
    // mywindow.close();
  }
};

export default ReportColab;
